import React, { useState } from "react";
import "./Feature.css";
import Aimodel from "../../images/ai-model.png";
import Graph from "../../images/graph.png";
import Check from "../../images/check.png";
import Trophy from "../../images/trophy.png";
import Without from "../../images/without.png";
import Market from "../../images/market.png";
import Seal from "../../images/seal.png";
import Transfer from "../../images/transfer.png";
import { BsRobot } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import { GoTrophy } from "react-icons/go";

function Feature() {
  const [activeTab, setActiveTab] = useState("tab6-1");

  return (
    <>
      <section
        id="features"
        className="feature-tab-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="feature-content-wrap">
                <div className="tab-content feature-tab-content">
                  <div className={`tab-pane ${activeTab === "tab6-1" ? "active" : ""}`} id="tab6-1">
                    <div className="row align-items-center justify-content-between pt-5">
                      <div className="col-md-6 col-lg-6">
                        <div id="tabmob" className="tab-content-wrap">
                          <span className="h1 display-3 color-secondary font-weight-bold mb-3 d-block text-left">
                            01.
                          </span>
                          <h3 className="Join text-left">Listing at Lightning Speed</h3>
                          <p className="paraag text-left">
                            Rev up your home’s market debut with Aire Brokers'
                            AI model assisted Flashlist. In just hours, your
                            listing goes from zero to hero, capturing buyers'
                            attention at the speed of light. Ready, set,
                            list—get your home in the fast lane to SOLD!
                       
                         
                            List with Lightning: Our AI doesn’t just list your
                            home; it launches it. With intuitive prompts and
                            swift execution, your sales journey starts with a
                            bang.
                          </p>
                          <a href="#" className="boost">
                            Discover the Fast Track
                            <span className="ti-angle-right ml-2" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <div className="img-wrap text-center">
                          <img
                            id="airevect"
                            src={Without}
                            alt="feature"
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === "tab6-2" ? "active" : ""}`} id="tab6-2">
                    <div className="row align-items-center justify-content-between pt-5">
                      <div className="col-md-6 col-lg-6">
                        <div id="tabmob" className="tab-content-wrap">
                          <span className="h1 display-3 color-secondary font-weight-bold mb-3 d-block text-left">
                            02.
                          </span>
                          <h3 className="Join text-left">Magnify Your Home’s Appeal</h3>
                          <p className="paraag text-left">
                            Unleash the full power of AI-driven marketing with
                            Aire Brokers. Our intelligent algorithms ensure your
                            listing shines in the spotlight, grabbing attention
                            and sparking interest like never before.
                       
                            With data-driven precision, your home’s best
                            features are broadcasted to the ideal audience,
                            ensuring a swift, surefire path to “SOLD”. Transform
                            views into offers as our platform does the heavy
                            lifting for you.
                       
                            Spread the Word: Step two in our 1-2-3-SOLD!
                            sequence amplifies your reach. Your home doesn’t
                            just get listed; it gets noticed.
                          </p>
                          <a href="#" className="boost">
                            Boost Your Listing Now
                            <span className="ti-angle-right ml-2" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <div className="img-wrap text-center">
                          <img
                            id="airevect"
                            src={Market}
                            alt="feature"
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === "tab6-3" ? "active" : ""}`} id="tab6-3">
                    <div className="row align-items-center justify-content-between pt-5">
                      <div className="col-md-6 col-lg-6">
                        <div id="tabmob" className="tab-content-wrap">
                          <span className="h1 display-3 color-secondary font-weight-bold mb-3 d-block text-left">
                            03.
                          </span>
                          <h3 className="Join text-left" >Offer Magnet</h3>
                          <p className="paraag text-left">
                            Attract offers like never before with Aire Brokers’
                            intelligent matching system. Our platform ensures
                            your listing gets in front of serious buyers,
                            priming the pump for competitive offers to flow your
                            way.
                       
                            Navigating offers is a breeze with our streamlined
                            interface—receive, review, and respond to potential
                            buyers with unprecedented ease. Your sale,
                            supercharged; your experience, simplified.
                          </p>
                          <a href="#" className="boost">
                            Accept Offers with Ease
                            <span className="ti-angle-right ml-2" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <div className="img-wrap text-center">
                          <img
                            id="airevect"
                            src={Seal}
                            alt="feature"
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === "tab6-4" ? "active" : ""}`} id="tab6-4">
                    <div className="row align-items-center justify-content-between pt-5">
                      <div className="col-md-6 col-lg-6">
                        <div id="tabmob" className="tab-content-wrap">
                          <span className="h1 display-3 color-secondary font-weight-bold mb-3 d-block text-left">
                            04.
                          </span>
                          <h3 className="Join text-left">No Sale? No Charge!</h3>
                          <p className="paraag text-left">
                            {" "}
                            Seal the deal with Aire Brokers' ironclad
                            1-2-3-SOLD! If your property doesn't sell, we've got
                            you. You’ll get every penny of your $497 listing fee
                            back. That's our zero-risk promise to you.
                         
                            With Aire Brokers, it’s not just about listing your
                            property; it's about celebrating its sale. And with
                            our money-back guarantee, you’ve got nothing to lose
                            and all the sale to gain. Ready to revolutionize
                            your real estate experience? We thought so.
                          </p>
                          <a href="#" className="boost">
                            Get Started Risk-Free
                            <span className="ti-angle-right ml-2" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <div className="img-wrap text-center">
                          <img
                            id="airevect"
                            src={Transfer}
                            alt="feature"
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                   
                   
                  }}
                  className=" nav-tabs  justify-content-center border-bottom-0"
                  data-tabs="tabs"
                >
                  <li className="">
                    <a
                      className={`nav-linke h6 d-flex align-items-center ${activeTab === "tab6-1" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab6-1")}
                      href="#tab6-1"
                    >
                      <div
                        style={{
                          backgroundColor: "#FFEEE8",
                          padding: "3.8px",
                          borderRadius: "50px",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "6px",
                        }}
                      >
                        <BsRobot
                          style={{
                            color: "#F15A24",
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      Flashlist AI Model
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-linke h6 d-flex align-items-center ${activeTab === "tab6-2" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab6-2")}
                      href="#tab6-2"
                    >
                      <div
                        style={{
                          backgroundColor: "#FFEEE8",
                          padding: "3.8px",
                          borderRadius: "50px",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "6px",
                        }}
                      >
                        <FaChartLine
                          style={{
                            color: "#F15A24",
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      Market with Mastery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-linke h6 d-flex align-items-center ${activeTab === "tab6-3" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab6-3")}
                      href="#tab6-3"
                    >
                      <div
                        style={{
                          backgroundColor: "#FFEEE8",
                          padding: "3.8px",
                          borderRadius: "50px",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "6px",
                        }}
                      >
                        <FaRegCheckCircle
                          style={{
                            color: "#F15A24",
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      Seal the Deal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-linke h6 d-flex align-items-center ${activeTab === "tab6-4" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab6-4")}
                      href="#tab6-4"
                    >
                      <div
                        style={{
                          backgroundColor: "#FFEEE8",
                          padding: "3.8px",
                          borderRadius: "50px",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "6px",
                        }}
                      >
                        <GoTrophy
                          style={{
                            color: "#F15A24",
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      Transfer Complete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Feature;
