import React, { useEffect,useState } from "react";
import Scrolltopimg from "../images/scroll-top.png";

export default function Scrolltop() {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 58) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


  const handleClick = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <button className={`scroll-top scroll-to-target ${isScrolled ? 'open':''}`} onClick={handleClick}>
        <img src={Scrolltopimg} alt="Scroll Top Logo" className="scroll-top-img" height={64} width={64} />
      </button>
    </>
  );
}
