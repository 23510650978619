import React from "react";
import "./Optimize.css";
import Appbeta from '../../images/optimize1.png';



function Optimize(){
    return(
        <>
            <section id="how-it-work" className="how-it-work ptb-100">
  <div className="container">
    <div className="row justify-content-between align-items-center">
      <div className="col-md-7 col-lg-7">
        <div className="img-wrap">
          <img src={Appbeta} alt="how work" className="img-fluid" />
        </div>
      </div>
      <div className="col-md-5 col-lg-5">
        <div className="content-info-wrap">
          <h2 className="Join text-left">
            Optimize with Advanced Integrations
          </h2>
          <p className="paraag">
            Harness the power of advanced integrations to take your real estate
            marketing to the next level. With Aire Brokers, you're not just
            listing properties; you're launching them into a digital ecosystem
            primed for success. From detailed analytics to expansive social
            reach, our tools are engineered to maximize your property's
            visibility and appeal. .
          </p>
          <div className="col  p-0">
            <div className="col-md-6 col-lg-6 col-12 p-0">
              <div className="detail-info-box text-left">
              <span
  className="iconspan1 text-center ti-palette d-inline-block mb-4 icon-sm text-white p-3 secondary-bg rounded shadow-icon"
/>

                <div className="detail-info-content">
                  <h5 className="mb-2">Precision Targeting</h5>
                  <p className="paraag">
                    Leverage detailed insights and smart algorithms to pinpoint
                    your ideal buyer demographic with precision.
                  </p>
                </div>
              </div>
            </div><br/>
            <div className="col-md-6 col-lg-6 col-12 p-0">
              <div className="detail-info-box text-left">
                <span className="iconspan2 ti-announcement d-inline-block mb-4 icon-sm text-white p-3 secondary-bg rounded" />
                <div className="detail-info-content">
                  <h5 className="mb-2">Expansive Exposure</h5>
                  <p className="paraag">
                    Cast a wide net with strategic social media campaigns that
                    turn viewers into leads, and leads into sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        </>
    );
}

export default Optimize;