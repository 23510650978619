import React from "react";
import "./Pricing.css";
import CombinedShape from "../../images/CombinedShape.png";

function Pricing() {
  return (
    <>
      <section id="pricing" className="pricing ptb-100 gray-light-bg">
        <div className="container position-relative">
          <img src={CombinedShape} alt="Combined Shape" className="combined-shape"/>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading text-center mb-4">
                <h2 className="Join">Pricing Advantage with AI Precision</h2>
                <p className="leade">
                  Unlock exceptional value with Aire Brokers' pricing. Our AI-driven
                  strategies ensure top market placement without the extra fees.
                  Choose your level of AI-powered innovation and support to excel in
                  today's market.
                </p>
              </div>
            </div>
          </div>
          <br/><br/>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12 d-flex">
              <div className="card text-center single-pricing-pack market-blast-package">
                <div className="card-header py-4 border-0 pricing-header">
                  <div className="h5 mb-0 text-left">Market Blast Package</div>
                  <div className="h1 mb-0">$<span className="price">497</span> / mon</div>
                </div>
                <div className="card-body">
                  <span className="cardSpan text-left">
                    For just $497, ignite your listing's visibility. This
                    package offers:
                  </span>
                  <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                    <li className="spanText"><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>NO real estate commissions!</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>Social Media Push</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>AI Listing Enhancement</li>
                    
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>Email Marketing Integration</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>Every contract template you need</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="#FA7848"/>
</svg>
</span>24/7 AI Chat Support to help you with all your questions</li>
                  </ul>
                  <a
                    href="https://sell.airebrokers.com/"
                    className="btn outline-btn mb-3"
                  >
                    Select Market Blast
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 d-flex">
              <div className="card popular-price text-center single-pricing-pack ultimate-seller-suite">
                <div className="card-header py-4 border-0 pricing-header">
                  <div className="h5 mb-0 text-left text-white">Ultimate Seller Suite</div>
                  <div className="h1 mb-0 text-white">$<span className="price ">997</span> / mon</div>
                </div>
                <div className="card-body">
                  <span className="cardSpan2 text-left">
                    Our premium package at $997 delivers the ultimate
                    selling toolkit
                  </span>
                  <ul className="list-unstyled text-sm mb-4 pricing-feature-list2">
                    <li className="spanText"><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>Everything in Market Blast</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>Drone Photography</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>Immersive 3D Tour</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>$50 Facebook Ad Credits</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>Dedicated Marketing Team to kick off your campaign</li>
                    <li><span className="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.1823 7.76894C17.7786 7.39211 17.1458 7.41393 16.7689 7.81768L10.423 14.6169L7.65079 12.2407C7.23147 11.8813 6.60017 11.9299 6.24074 12.3492C5.88132 12.7685 5.92988 13.3998 6.34921 13.7593L9.84921 16.7593C10.256 17.108 10.8654 17.074 11.2311 16.6823L18.2311 9.18232C18.6079 8.77857 18.5861 8.14578 18.1823 7.76894Z" fill="white"/>
</svg>
</span>Professional Interior Photos</li>
                  </ul>
                  <a
                    href="https://sell.airebrokers.com/"
                    className="btn solid-btn mb-3"
                  >
                    Go Ultimate
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
