import React from "react";
import "./Faq.css";

function Faq() {
  return (
    <>
      <div id="faq" className="ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading text-center mb-4">
                <h2 className="Join">Got Questions? We've Got Answers</h2>
                <p className="paraag">
                  Navigating the real estate market has never been easier. Below are
                  some of the most common questions we get, along with the answers
                  that showcase why Aire Brokers is your go-to for home sales.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div id="accordion" className="accordion">
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> What fees are involved in
                      listing my home?
                    </h6>
                  </a>
                  <div
                    id="collapseOne"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        A simple, one-time $497 platform fee is all you pay to list
                        your home. No hidden costs, no commission – just one flat
                        fee for full access to our revolutionary selling platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseTwo"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> How quickly will my home
                      be listed after signing up?
                    </h6>
                  </a>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        Your home will be listed within hours* of signing up. Our
                        streamlined process and intuitive app design make it quick
                        and easy to get your property on the market. (pending
                        identity verification)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseThree"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> How do I see previous
                      orders?
                    </h6>
                  </a>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft beer
                        farm-to-table, raw denim aesthetic synth nesciunt you
                        probably haven't heard of them accusamus labore sustainable
                        VHS.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseFour"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> What happens if my
                      property doesn't sell?
                    </h6>
                  </a>
                  <div
                    id="collapseFour"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        We stand by our 1-2-3-SOLD! promise — if your property
                        doesn’t sell, we refund the $497 listing fee. It’s
                        risk-free!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseFive"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> How does the Aire
                      Brokers app help with marketing my property?
                    </h6>
                  </a>
                  <div
                    id="collapseFive"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        Our app is a powerhouse, using AI to target the right buyers
                        through intelligent marketing, ensuring your property gets
                        seen by those most likely to make a purchase.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseSix"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span /> How do the AI
                      features benefit my home sale?
                    </h6>
                  </a>
                  <div
                    id="collapseSix"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        From setting the right price to creating the ideal listing
                        description,, our AI features analyze data to make your
                        selling process smarter, not harder.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0">
                  <a
                    className="card-header collapsed"
                    data-toggle="collapse"
                    href="#collapseSeven"
                    aria-expanded="false"
                  >
                    <h6 className="qst">
                      <span />
                      Are there additional costs for the 3D tour and drone
                      photography?
                    </h6>
                  </a>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p className="qstPara">
                        These premium features are included in our Ultimate Seller
                        Suite for $997, giving you high-end marketing tools at an
                        unbeatable value.!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
