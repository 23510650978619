import React from "react";
import "./Hero.css";
import Mobile from "../../images/Untitled-removebg-preview (1).png";

function Hero() {
  return (
    <section style={{overflow:'visible'}} className="hero-equal-height ptb-100 gradient-overlay ">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-8 col-lg-8 mt-5">
            <div className="hero-content-left text-white my-lg-0 my-md-5 my-sm-5 my-5">
              <h2 className="HeroTitle">
                Aire Brokers<br />
                List Rapidly, <span className="HerorSpan">Sell Smartly</span>
              </h2>

              <p className="lead text-left mb-3">
                Forget 6% commissions. Keep the $30K (Based on a 500k sale
                price). Our AI-driven app slashes the<br /> sell-game, pinpointing
                your profits with razor-edge tech for just $497. Navigate the
                realty seas with <br />Aire Brokers—where listings launch and savings
                soar.
              </p>
              <div className="action-btns download-btn mt-4 text-left">
                <a
                  href="#download"
                  className="page-scroll btn solid-white-btn animated-btn mr-lg-3"
                >
                  Download and Save
                </a>
                <a
                  href="#contact"
                  className="page-scroll btn outline-white-btn animated-btn"
                >
                  Explore the Edge
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 position-relative d-flex justify-content-center align-items-center overflow-visible">
            <div className="iphone-wrap position-absolute">
              <img src={Mobile} alt="app" className="img-fluid mobile1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
