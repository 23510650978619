import React, { useEffect, useState } from "react";
import "./Header.css";
import Logo from '../../images/logo.png';
import PageScrollLink from '../Pagescroll/Pagescroll.js'

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 58) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className="header" id="header">
        <nav className={`navbar navbar-expand-lg fixed-top bg-transparent ${isScrolled ? 'affix' : ''}`}>
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                src={Logo}
                width="133"
                alt="logo"
                className="img-fluid"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse h-auto"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto menu">
                <li className="">
                  <PageScrollLink href="#header" className="">
                    Home
                  </PageScrollLink>
                </li>
              
                <li>
                  <PageScrollLink href="#features" className="page-scroll">
                    <b>Features</b>
                  </PageScrollLink>
                </li>
                <li>
                  <PageScrollLink href="#how-it-work" className="page-scroll">
                    <b>1-2-3-SOLD!</b>
                  </PageScrollLink>
                </li>
                <li>
                  <PageScrollLink href="#screenshots" className="page-scroll">
                    <b>Screenshots</b>
                  </PageScrollLink>
                </li>
                <li>
                  <PageScrollLink href="#pricing" className="page-scroll">
                    <b>Pricing</b>
                  </PageScrollLink>
                </li>
                <li>
                  <PageScrollLink href="#faq" className="page-scroll">
                    <b>FAQ</b>
                  </PageScrollLink>
                </li>
                <li>
                  <PageScrollLink href="#contact" className="page-scroll contact-button">
                    <b className="ContactBtn">Contact Us</b>
                  </PageScrollLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
