import React from 'react';

class PageScrollLink extends React.Component {
  handleClick = (event) => {
    event.preventDefault();
    const $anchor = event.currentTarget;
    const targetElement = document.querySelector($anchor.getAttribute('href'));
    if (targetElement) {
      const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offsetTop - 58,
        behavior: 'smooth' // Smooth scrolling
      });
    }
  };

  render() {
    return (
      <a href={this.props.href} className="page-scroll" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

export default PageScrollLink;