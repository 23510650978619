import React from "react";


function Contact(){
    return(

<>

  <section id="contact" className="contact-us-section contact-us pb-100">
    <div className="container">
      <div className="row justify-content-around">
        <div id="tabmob" className="col-md-6">
        
          <div
            style={{ borderRadius: 24 }}
            className="contact-us-form "
          >
            <h4 style={{fontSize:'35px'}} className="Join text-left">Get Started with Aire Brokers</h4>
            <p className="leade text-left">
              Dive into the new era of real estate—right from your smartphone.
              Download the Aire Brokers app today and start the journey to sell
              your home with confidence and ease. Or click to chat with us and
              get your questions answered instantly!
            </p>
            <a style={{color:'#363BA6'}} href="#" className="">
              Chat With Us
              <span className="ti-angle-right ml-2" />
            </a>
            <br /><br />
            <div style={{ display: "flex", justifyContent: "left" }}>
            <a style={{}} href="#" className="btn outline-btn solid mr-3">
              {" "}
              List Now  
              <span className="ti-arrow-right pl-2" />
            </a>
            </div>
          </div>
        </div>
        <div id="tabmob" className="col-md-5">
          <div className="contact-us-content">
      
            <h4  style={{fontSize:'35px'}} className="Join text-left">Join Our Broker Network</h4>
            <p className="leade text-left">
              Aire Brokers is building a community of innovative real estate
              professionals. Our technology and partnership portal offer
              opportunities to elevate your business and revolutionize the
              industry. Join us to shape the future of real estate.
            </p>
            <br />
            <div style={{ display: "flex", justifyContent: "left" }}>
            <a style={{}} href="#" className="btn outline-btn solid mr-3">
              {" "}
              Become a partner
              <span className="ti-arrow-right pl-2" />
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
</>
);
}


export default Contact;