


import React from 'react';
import './css/themify-icons.css';
import './App.css';
import Header from './component/Header/Header';
import Hero from './component/Hero/Hero';
import Join from './component/Join/Join';
import Optimize from './component/Optimize/Optimize';
import Feature from './component/Feature/Feature';
import Showoff from './component/Showoff/Showoff';
import Airbroker from './component/Airbroker/Airbroker';
import Pricing from './component/Pricing/Pricing';
import Faq from './component/FAQ/Faq';
import Contact from './component/Contact/Contact';
import Footer from './component/Footer/Footer';
import Scrolltop from './Scrolltop/Scrolltop';


function App() {
  return (
    <div className="App">
      <div className='top-sec'>
        <Header/>

      </div>
      <div className='main'>
        <Hero/>
        <Join/>
        <Feature/>
        <Optimize/>
        <Showoff/>
        <Airbroker/>
        <Pricing/>
        <Faq/>
        <Contact/>
      </div>
      <div className='footer'>
        <Footer/>
      </div>
      <div className='scrolltop'>
        <Scrolltop/>
      </div>
    </div>
  );
}

export default App;
