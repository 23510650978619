import React from "react";
import "./Airbroker.css";
import Welcome from "../../images/downloadsection.png";
import Logo from "../../images/logo.png";

function Airbroker() {
  return (
    <>
      <section id="download" className="download-section">
        <div className="container">
          <div className="row justify-content-around align-items-end">
            <div id="tabmob" className="col-md-7 col-lg-6">
              <div
                style={{ paddingBottom: 145 }}
                className="download-txt text-white ptb-100"
              >
                <h2 className="Join">Get the Aire Brokers App</h2>
                <p className="leade text-left">
                  Step into the future of real estate with just one tap.
                  Download our app and start the journey to selling your home
                  with ease, speed, and confidence. Your next big move is right
                  at your fingertips.
                </p>

                <div className="action-btns download-btn mt-4">
                  <a href="https://sell.airebrokers.com/">
                    <button className="DownBtn" style={{ width: "11rem" }}>
                      List Now
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-6 position-relative">
              <div className="d-flex justify-content-center align-items-center position-relative welcome-container">
                <img className="img-fluid welcome-img" src={Welcome} alt="download" />
                <img className="position-absolute logo-center" src={Logo} width="133" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Airbroker;
