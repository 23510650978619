import React from "react";
import "./Showoff.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import slide1 from '../../images/slide.png';
import slide2 from '../../images/slide.png';
import slide3 from '../../images/slide.png';
import slide4 from '../../images/slide.png';
import slide5 from '../../images/slide.png';
import slide6 from '../../images/slide.png';
import centerSlide from '../../images/appcenter.png';  // Placeholder for center slide image

const options = {
    loop: true,
    margin: 0,
    center: true,
    items: 1,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,
        }
    },
};

function Showoff() {
    return (
        <>
            <section id="screenshots" className="screenshots-section ptb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-8">
                            <div className="section-heading text-center mb-5">
                                <h2 className="Join">Showcase of Success</h2>
                                <p className="leade">
                                    Dive into our digital domain where every swipe brings you closer
                                    to SOLD. Our app’s interface is a fusion of form and function,
                                    crafted to streamline your real estate journey. From listing to
                                    closing, experience the power of a platform that's always one step
                                    ahead, connecting you to the pulse of the property market.{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="screen-slider-content">
                        <OwlCarousel  {...options}>
                            <div className='item'>
                                <img src={slide1} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item'>
                                <img src={slide2} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item center-slide'>
                                <img src={centerSlide} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item'>
                                <img src={slide3} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item'>
                                <img src={slide4} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item'>
                                <img src={slide5} className="img-fluid" alt="screenshots" />
                            </div>
                            <div className='item'>
                                <img src={slide6} className="img-fluid" alt="screenshots" />
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Showoff;
